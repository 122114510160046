import {Injectable} from '@angular/core';
import {FilterBuilder} from "@core/shared/models/filter";
import {BehaviorSubject, Subject} from "rxjs";
import {OFFER_CREATOR_SEARCH_SESSION_STORAGE_IDENTIFIER, OfferCreatorSearchSessionFilter} from "@core/shared/models/offer/offer-creator-search";
import {JwtToken} from "@core/shared/models/jwt";
import {AuthenticationService} from "@core/shared/services/authentication.service";
import {Society} from "@core/shared/models/society";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class OfferCreatorSearchService {

    private _totalOfferCreators: number = 0;

    public filterBuilder: FilterBuilder = new FilterBuilder();

    public resetFilter$: Subject<void> = new Subject();

    public selectedOfferCreators$: BehaviorSubject<Society[]> = new BehaviorSubject([]);

    public selectOfferCreatorEnabled: boolean = false;

    public selectOfferCreatorAllowed: boolean = false;

    public maxOfferCreatorSelectionAllowed: number = 0;

    public additionalFilterParams$: BehaviorSubject<string[]> = new BehaviorSubject([]);

    constructor(
        private _authenticationService: AuthenticationService,
        private _translateService: TranslateService
    ) {
        this._handleSession();
    }

    private _handleSession(): void {

        this._authenticationService.jwtToken.subscribe((value: JwtToken): void => {

            if(!value.isAuthenticated){

                sessionStorage.removeItem(OFFER_CREATOR_SEARCH_SESSION_STORAGE_IDENTIFIER);
            }
        });
    }

    public getSessionFilter(key: string): OfferCreatorSearchSessionFilter {

        const filters: OfferCreatorSearchSessionFilter[] = this.sessionFilters;

        if (!filters || !Boolean(filters.length)){

            return null;
        }

        return filters.find((sessionFilter: OfferCreatorSearchSessionFilter): boolean => {

            return sessionFilter.key === key;
        });
    }

    public hasSessionFilter(key: string): boolean {

        const filters: OfferCreatorSearchSessionFilter[] = this.sessionFilters;

        if (!filters || !Boolean(filters.length)){

            return false;
        }

        return !!filters.find((sessionFilter: OfferCreatorSearchSessionFilter): boolean => {

            return sessionFilter.key === key;
        });
    }

    public selectOfferCreator(offerCreator: Society, mode: 'quotation' | 'catalog'): void {

        if(!this.selectOfferCreatorAllowed || !this.isOfferCreatorSelectionAllowed(offerCreator, mode)){

            return;
        }

        const items: Society[] = this.selectedOfferCreators$.value;

        items.push(offerCreator);

        this.selectedOfferCreators$.next(items);
    }

    public unselectOfferCreator(offerCreator: Society): void {

        const items: Society[] = this.selectedOfferCreators$.value;

        const index: number = items.findIndex((item: Society): boolean => {

            return offerCreator.id === item.id;
        });

        items.splice(index, 1);

        this.selectedOfferCreators$.next(items);
    }

    public isSelectedOfferCreator(offerCreator: Society): boolean {

        const match: Society = this.selectedOfferCreators$.value.find((item: Society): boolean => {

            return offerCreator.id === item.id;
        });

        return !!match;
    }

    public isOfferCreatorSelectionAllowed(offerCreator: Society, mode: 'quotation' | 'catalog'): boolean {

        if(mode === 'quotation'){

            return (offerCreator.details.customerTypologies.length > 0) && (offerCreator.details.regions.length > 0 || offerCreator.details.allRegions);
        }

        return true;
    }

    public offerCreatorSelectionDisableReason(offerCreator: Society, mode: 'quotation' | 'catalog'): string {

        if(!this.selectOfferCreatorAllowed){

            return this._translateService.instant('offerCreator.selection.unauthorized.description.value', { count: this.maxOfferCreatorSelectionAllowed });
        }

        if((mode === 'quotation') && ((offerCreator.details.customerTypologies.length < 1) || (offerCreator.details.regions.length < 1 && !offerCreator.details.allRegions))){

            return this._translateService.instant('offerCreator.selection.incorrectConfiguration.description.value');
        }

        return null;
    }

    get hasSessionFilters(): boolean {

        return !!sessionStorage.getItem(OFFER_CREATOR_SEARCH_SESSION_STORAGE_IDENTIFIER);
    }

    get sessionFilters(): OfferCreatorSearchSessionFilter[] {

        return JSON.parse(sessionStorage.getItem(OFFER_CREATOR_SEARCH_SESSION_STORAGE_IDENTIFIER));
    }

    set sessionFilters(value: OfferCreatorSearchSessionFilter[]) {

        sessionStorage.setItem(OFFER_CREATOR_SEARCH_SESSION_STORAGE_IDENTIFIER, JSON.stringify(value));
    }

    get totalOfferCreators(): number {

        return this._totalOfferCreators;
    }

    set totalOfferCreators(value: number) {

        this._totalOfferCreators = value;
    }
}
