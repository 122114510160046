import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from "@material/material.module";
import {TranslateModule} from "@ngx-translate/core";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {LibModule} from "@lib/lib.module";
import {HeaderComponent} from './components/header/header.component';
import {NavigationModule} from "@app/navigation/navigation.module";
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {PageSigninComponent} from './pages/page-signin/page-signin.component';
import {PageDashboardComponent} from './pages/page-dashboard/page-dashboard.component';
import {ReactiveFormsModule} from "@angular/forms";
import {AuthenticationGuard} from "@core/shared/guards/authentication.guard";
import {JwtInterceptor} from "@core/shared/interceptors/jwt.interceptor";
import {HttpErrorInterceptor} from "@core/shared/interceptors/http-error.interceptor";
import {LoaderComponent} from './components/loader/loader.component';
import {LoaderInterceptor} from "@core/shared/interceptors/loader.interceptor";
import {TextFilterComponent} from "@core/components/filter/text-filter/text-filter.component";
import {SelectFilterComponent} from './components/filter/select-filter/select-filter.component';
import {MatLegacyPaginatorIntl as MatPaginatorIntl} from "@angular/material/legacy-paginator";
import {CustomPaginator} from "@core/shared/paginators/custom-paginator";
import {PageAdministratorListComponent} from './pages/page-administrator/page-administrator-list/page-administrator-list.component';
import {PageAdministratorCreateComponent} from './pages/page-administrator/page-administrator-create/page-administrator-create.component';
import {PageAdministratorUpdateComponent} from './pages/page-administrator/page-administrator-update/page-administrator-update.component';
import {PageUserValidateAccountComponent} from './pages/page-user/page-user-validate-account/page-user-validate-account.component';
import {TranslationAddComponent} from './components/translation/translation-add/translation-add.component';
import {SelectLocaleDialogComponent} from './components/locale/select-locale-dialog/select-locale-dialog.component';
import {PageInvoiceListComponent} from './pages/page-invoice/page-invoice-list/page-invoice-list.component';
import {CustomDatePipe} from './shared/pipes/custom-date.pipe';
import {ActivationPipe} from './shared/pipes/activation.pipe';
import {DateIntervalFilterComponent} from './components/filter/date-interval-filter/date-interval-filter.component';
import {SelectArrayFilterComponent} from './components/filter/select-array-filter/select-array-filter.component';
import {SelectArrayMultipleFilterComponent} from './components/filter/select-array-multiple-filter/select-array-multiple-filter.component';
import {PageSubscriptionListComponent} from './pages/page-subscription/page-subscription-list/page-subscription-list.component';
import {PageUserListComponent} from "@core/pages/page-user/page-user-list/page-user-list.component";
import {PageUserCreateComponent} from "@core/pages/page-user/page-user-create/page-user-create.component";
import {PageUserUpdateComponent} from "@core/pages/page-user/page-user-update/page-user-update.component";
import {LocationComponent} from './components/location/location.component';
import {PageUserForgetPasswordComponent} from './pages/page-user/page-user-forget-password/page-user-forget-password.component';
import {RouterModule} from "@angular/router";
import {PageUserResetPasswordComponent} from './pages/page-user/page-user-reset-password/page-user-reset-password.component';
import {PageUserAccountComponent} from './pages/page-user/page-user-account/page-user-account.component';
import {PageAccountLayoutComponent} from './pages/page-account-layout/page-account-layout.component';
import {AddCustomerTypologyDialogComponent} from './components/customer-typology/add-customer-typology-dialog/add-customer-typology-dialog.component';
import {PageCollaboratorListComponent} from './pages/page-collaborator/page-collaborator-list/page-collaborator-list.component';
import {PageCollaboratorCreateComponent} from './pages/page-collaborator/page-collaborator-create/page-collaborator-create.component';
import {PageCollaboratorUpdateComponent} from './pages/page-collaborator/page-collaborator-update/page-collaborator-update.component';
import {PageOfferAttributeCreateComponent} from './pages/page-offer/page-offer-attribute/page-offer-attribute-create/page-offer-attribute-create.component';
import {PageOfferAttributeUpdateComponent} from './pages/page-offer/page-offer-attribute/page-offer-attribute-update/page-offer-attribute-update.component';
import {PageOfferAttributeListComponent} from './pages/page-offer/page-offer-attribute/page-offer-attribute-list/page-offer-attribute-list.component';
import {PageChannelRestrictedListComponent} from '@core/pages/page-channel/page-channel-restricted/page-channel-restricted-list/page-channel-restricted-list.component';
import {PageLicenceUpdateComponent} from './pages/page-subscription/page-subscription-licence/page-licence-update/page-licence-update.component';
import {TermsAndConditionsComponent} from './components/terms-and-conditions/terms-and-conditions.component';
import {FullCalendarModule} from "@fullcalendar/angular";
import {PageOfferCreateComponent} from './pages/page-offer/page-offer-create/page-offer-create.component';
import {PageOfferUpdateComponent} from './pages/page-offer/page-offer-update/page-offer-update.component';
import {OfferPeriodCreateComponent} from './components/offer/offer-period/offer-period-create/offer-period-create.component';
import {OfferPriceFormComponent} from './components/offer/offer-price/offer-price-form/offer-price-form.component';
import {OfferLocationFormComponent} from './components/offer/offer-location/offer-location-form/offer-location-form.component';
import {OfferPeriodDeleteComponent} from './components/offer/offer-period/offer-period-delete/offer-period-delete.component';
import {OfferPeriodDeleteDialogComponent} from './components/offer/offer-period/offer-period-delete/offer-period-delete-dialog/offer-period-delete-dialog.component';
import {OfferPresentialFormComponent} from './components/offer/offer-presential/offer-presential-form/offer-presential-form.component';
import {PageOfferListComponent} from "@core/pages/page-offer/page-offer-list/page-offer-list.component";
import {OfferInterestCreateComponent} from './components/offer/offer-interest/offer-interest-create/offer-interest-create.component';
import {OfferNoIncludedCreateComponent} from './components/offer/offer-no-included/offer-no-included-create/offer-no-included-create.component';
import {OfferIncludedCreateComponent} from './components/offer/offer-included/offer-included-create/offer-included-create.component';
import {OfferProviderCreateComponent} from './components/offer/offer-provider/offer-provider-create/offer-provider-create.component';
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {LeafletModule} from "@asymmetrik/ngx-leaflet";
import {OfferMapComponent} from './components/offer/offer-map/offer-map.component';
import {LeafletMarkerClusterModule} from "@asymmetrik/ngx-leaflet-markercluster";
import {OfferFilterComponent} from './components/offer/offer-filter/offer-filter.component';
import {OfferFilterPanelComponent} from './components/offer/offer-filter-panel/offer-filter-panel.component';
import {OfferCardComponent} from './components/offer/offer-card/offer-card.component';
import {OfferListComponent} from './components/offer/offer-list/offer-list.component';
import {OfferCatalogCreateComponent} from './components/offer/offer-catalog/offer-catalog-create/offer-catalog-create.component';
import {PageChannelCreateComponent} from './pages/page-channel/page-channel-create/page-channel-create.component';
import {PageChannelListComponent} from './pages/page-channel/page-channel-list/page-channel-list.component';
import {OfferSearchComponent} from './components/offer/offer-search/offer-search.component';
import {PageOfferReadComponent} from "@core/pages/page-offer/page-offer-read/page-offer-read.component";
import {PageChannelUpdateComponent} from './pages/page-channel/page-channel-update/page-channel-update.component';
import {OfferAttributesFormComponent} from './components/offer/form/offer-attributes-form/offer-attributes-form.component';
import {OfferAccessRequestCreateComponent} from './components/offer/offer-access-request/offer-access-request-create/offer-access-request-create.component';
import {OfferAccessRequestCreateDialogComponent} from './components/offer/offer-access-request/offer-access-request-create/offer-access-request-create-dialog/offer-access-request-create-dialog.component';
import {OfferPictureSourcePipe} from './shared/pipes/offer/offer-picture-source.pipe';
import {OfferProgramPictureSourcePipe} from "./shared/pipes/offer/offer-program-picture-source.pipe";
import {OfferPictureGalleryComponent} from './components/offer/offer-picture/offer-picture-gallery/offer-picture-gallery.component';
import {OfferPictureGalleryDialogComponent} from './components/offer/offer-picture/offer-picture-gallery/offer-picture-gallery-dialog/offer-picture-gallery-dialog.component';
import {ImageSourcePipe} from './shared/pipes/image-source.pipe';
import {OfferDateEngineComponent} from './components/offer/offer-date/offer-date-engine/offer-date-engine.component';
import {OfferProgramsFormComponent} from './components/offer/form/offer-programs-form/offer-programs-form.component';
import {SelectBooleanFilterComponent} from './components/filter/select-boolean-filter/select-boolean-filter.component';
import {CheckboxBooleanFilterComponent} from './components/filter/checkbox-boolean-filter/checkbox-boolean-filter.component';
import {OfferAccessRequestMultipleCreateDialogComponent} from './components/offer/offer-access-request/offer-access-request-multiple/offer-access-request-multiple-create/offer-access-request-multiple-create-dialog/offer-access-request-multiple-create-dialog.component';
import {OfferAccessRequestMultipleCreateComponent} from './components/offer/offer-access-request/offer-access-request-multiple/offer-access-request-multiple-create/offer-access-request-multiple-create.component';
import {PageDistributorApprovalListComponent} from './pages/page-distributor/page-distributor-approval/page-distributor-approval-list/page-distributor-approval-list.component';
import {MomentModule} from "ngx-moment";
import {OfferCatalogRequestViewComponent} from './components/offer/offer-catalog/offer-catalog-request/offer-catalog-request-view/offer-catalog-request-view.component';
import {OfferCatalogRequestViewDialogComponent} from './components/offer/offer-catalog/offer-catalog-request/offer-catalog-request-view/offer-catalog-request-view-dialog/offer-catalog-request-view-dialog.component';
import {OfferCatalogRequestRevokeDialogComponent} from './components/offer/offer-catalog/offer-catalog-request/offer-catalog-request-revoke/offer-catalog-request-revoke-dialog/offer-catalog-request-revoke-dialog.component';
import {PageSocietyDistributorListComponent} from './pages/page-society/page-society-distributor/page-society-distributor-list/page-society-distributor-list.component';
import {PageSocietyDistributorUpdateComponent} from './pages/page-society/page-society-distributor/page-society-distributor-update/page-society-distributor-update.component';
import {OfferBookingRequestCreateComponent} from './components/offer/offer-booking/offer-booking-request/offer-booking-request-create/offer-booking-request-create.component';
import {PageMenuCreateComponent} from "@core/pages/page-menu/page-menu-create/page-menu-create.component";
import {PageMenuListComponent} from "@core/pages/page-menu/page-menu-list/page-menu-list.component";
import {PageMenuUpdateComponent} from "@core/pages/page-menu/page-menu-update/page-menu-update.component";
import {PageCreateComponent} from "@core/pages/page/page-create/page-create.component";
import {PageListComponent} from "@core/pages/page/page-list/page-list.component";
import {PageUpdateComponent} from "@core/pages/page/page-update/page-update.component";
import {PageCategoryCreateComponent} from "@core/pages/page-category/page-category-create/page-category-create.component";
import {PageCategoryListComponent} from "@core/pages/page-category/page-category-list/page-category-list.component";
import {PageCategoryUpdateComponent} from "@core/pages/page-category/page-category-update/page-category-update.component";
import {CustomerFormCreateComponent} from './components/customer/form/customer-form-create/customer-form-create.component';
import {PageCategoryReadComponent} from "@core/pages/page-category/page-category-read/page-category-read.component";
import {PageReadComponent} from "@core/pages/page/page-read/page-read.component";
import {CmsButtonComponent} from "@core/components/cms/element/button/button.component";
import {CmsTextComponent} from "@core/components/cms/element/text/text.component";
import {CmsFileComponent} from "@core/components/cms/element/file/file.component";
import {CmsIframeComponent} from "@core/components/cms/element/iframe/iframe.component";
import {CmsVideoComponent} from "@core/components/cms/element/video/video.component";
import {CmsSlideshowComponent} from "@core/components/cms/element/slideshow/slideshow.component";
import {CmsImageComponent} from "@core/components/cms/element/image/image.component";
import {PageOfferMarkupListComponent} from './pages/page-offer/page-offer-markup/page-offer-markup-list/page-offer-markup-list.component';
import {MarkupUpdateGroupedDialogComponent} from './components/markup/markup-update/markup-update-grouped/markup-update-grouped-dialog/markup-update-grouped-dialog.component';
import {OfferNetworkProviderCreateComponent} from "@core/components/offer/offer-network-provider/offer-network-provider-create/offer-network-provider-create.component";
import {PageOfferGroupNetworkProviderListComponent} from "@core/pages/page-offer/page-offer-group-network-provider/page-offer-group-network-provider-list/page-offer-group-network-provider-list.component";
import {PageOfferGroupNetworkProviderCreateComponent} from "@core/pages/page-offer/page-offer-group-network-provider/page-offer-group-network-provider-create/page-offer-group-network-provider-create.component";
import {PageOfferGroupNetworkProviderUpdateComponent} from "@core/pages/page-offer/page-offer-group-network-provider/page-offer-group-network-provider-update/page-offer-group-network-provider-update.component";
import {PageBookingListComponent} from "@core/pages/page-booking/page-booking-list/page-booking-list.component";
import {PageBookingReadComponent} from "@core/pages/page-booking/page-booking-read/page-booking-read.component";
import {ColorPickerModule} from "ngx-color-picker";
import {PageOfferMarkupCatalogListComponent} from "@core/pages/page-offer/page-offer-markup-catalog/page-offer-markup-catalog-list/page-offer-markup-catalog-list.component";
import {PagePromotionListComponent} from './pages/page-promotion/page-promotion-list/page-promotion-list.component';
import {PagePromotionCreateComponent} from './pages/page-promotion/page-promotion-create/page-promotion-create.component';
import {PagePromotionUpdateComponent} from './pages/page-promotion/page-promotion-update/page-promotion-update.component';
import {NgxMaterialTimepickerModule} from "ngx-material-timepicker";
import {PromotionPeriodCalendarComponent} from './components/promotion/promotion-period/promotion-period-calendar/promotion-period-calendar.component';
import {PromotionPeriodCreateComponent} from './components/promotion/promotion-period/promotion-period-create/promotion-period-create.component';
import {PromotionPeriodCreateDialogComponent} from './components/promotion/promotion-period/promotion-period-create/promotion-period-create-dialog/promotion-period-create-dialog.component';
import {PromotionPeriodDeleteDialogComponent} from './components/promotion/promotion-period/promotion-period-delete/promotion-period-delete-dialog/promotion-period-delete-dialog.component';
import {PromotionPeriodDeleteComponent} from './components/promotion/promotion-period/promotion-period-delete/promotion-period-delete.component';
import {PageOfferOptionListComponent} from "@core/pages/page-offer/page-offer-option/page-offer-option-list/page-offer-option-list.component";
import {PageOfferOptionCreateComponent} from "@core/pages/page-offer/page-offer-option/page-offer-option-create/page-offer-option-create.component";
import {PageOfferOptionUpdateComponent} from "@core/pages/page-offer/page-offer-option/page-offer-option-update/page-offer-option-update.component";
import {OfferOptionPresentialFormComponent} from './components/offer/offer-option/offer-option-presential/offer-option-presential-form/offer-option-presential-form.component';
import { OfferOptionPeriodCalendarComponent } from './components/offer/offer-option/offer-option-period/offer-option-period-calendar/offer-option-period-calendar.component';
import { OfferOptionPeriodCreateComponent } from './components/offer/offer-option/offer-option-period/offer-option-period-create/offer-option-period-create.component';
import { OfferOptionPeriodCreateDialogComponent } from './components/offer/offer-option/offer-option-period/offer-option-period-create/offer-option-period-create-dialog/offer-option-period-create-dialog.component';
import { OfferOptionPeriodDeleteComponent } from './components/offer/offer-option/offer-option-period/offer-option-period-delete/offer-option-period-delete.component';
import { OfferOptionPeriodDeleteDialogComponent } from './components/offer/offer-option/offer-option-period/offer-option-period-delete/offer-option-period-delete-dialog/offer-option-period-delete-dialog.component';
import {SubscriptionDialogComponent} from "@core/components/account/subscription/subscription-dialog/subscription-dialog.component";
import { PageOfferPermanentOptionListComponent } from './pages/page-offer/page-offer-permanent-option/page-offer-permanent-option-list/page-offer-permanent-option-list.component';
import {SubscriptionFormComponent} from "@core/components/account/subscription/subscription-form/subscription-form.component";
import {PageSubscriptionPaymentComponent} from "@core/pages/page-subscription/page-subscription-payment/page-subscription-payment.component";
import {NumberCardDirective} from "@core/shared/directives/payment/number-card";
import {CvvCardDirective} from "@core/shared/directives/payment/cvv-card";
import {DateCardDirective} from "@core/shared/directives/payment/date-card";
import {PageSubscriptionPaymentResponseComponent} from "@core/pages/page-subscription/page-subscription-payment-response/page-subscription-payment-response.component";
import {PagePromotionSubscriptionReadComponent} from "@core/pages/page-promotion-subscription/page-promotion-read/page-promotion-subscription-read.component";
import {PagePromotionSubscriptionCreateComponent} from "@core/pages/page-promotion-subscription/page-promotion-subscription-create/page-promotion-subscription-create.component";
import {PagePromotionSubscriptionListComponent} from "@core/pages/page-promotion-subscription/page-promotion-subscription-list/page-promotion-subscription-list.component";
import { InputTextStripTagsDirective } from './shared/directives/input-text-strip-tags.directive';
import { ParsePricePipe } from './shared/pipes/parse-price.pipe';
import { InputTextParsePriceDirective } from './shared/directives/input-text-parse-price.directive';
import {ClipboardModule} from "ngx-clipboard";
import { InputTextParseMarkupDirective } from './shared/directives/input-text-parse-markup.directive';
import { SafeHtmlPipe } from './shared/pipes/safe-html.pipe';
import {PageReferenceReadComponent} from "@core/pages/page-reference/page-reference-read/page-reference-read.component";
import { PageSocietyProviderListComponent } from './pages/page-society/page-society-provider/page-society-provider-list/page-society-provider-list.component';
import { PageSocietyProviderCreateDialogComponent } from './pages/page-society/page-society-provider/page-society-provider-create/page-society-provider-create-dialog/page-society-provider-create-dialog.component';
import { PageSocietyProviderSendRequestDialogComponent } from './pages/page-society/page-society-provider/page-society-provider-send-request/page-society-provider-send-request-dialog/page-society-provider-send-request-dialog.component';
import { PageSocietyProviderReadComponent } from './pages/page-society/page-society-provider/page-society-provider-read/page-society-provider-read.component';
import { GooglePlacesLocationComponent } from './components/location/apis/google-places-location/google-places-location.component';
import {NgxIntlTelInputModule} from "@le2xx/ngx-intl-tel-input";
import { InputCapitalizeDirective } from "@core/shared/directives/input-capitalize.directive";
import {OfferItemComponent} from "@core/components/offer/offer-item/offer-item.component";
import {InputTextCountLimitDirective} from "@core/shared/directives/input-text-count-limit.directive";
import { RoleAdvantageComponent } from './components/account/role-advantages/role-advantage.component';
import {PageChannelReadComponent} from "@core/pages/page-channel/page-channel-read/page-channel-read.component";
import { IntroductionDialogComponent } from './components/account/introduction-dialog/introduction-dialog.component';
import { LocaleSwitchComponent } from './components/locale/locale-switch/locale-switch.component';
import { TimezoneSelectComponent } from './components/timezone/timezone-select/timezone-select.component';
import { ConditionOfUseDialogComponent } from './components/condition-of-use/condition-of-use-dialog.component';
import {DragDropModule} from "@angular/cdk/drag-drop";
import { ChannelOfferCardPreviewComponent } from './components/channel/channel-offer/channel-offer-card/channel-offer-card-preview/channel-offer-card-preview.component';
import { ChannelOfferViewPreviewComponent } from './components/channel/channel-offer/channel-offer-card/channel-offer-view-preview/channel-offer-view-preview.component';
import {ShowHidePasswordDirective} from "@core/shared/directives/show-hide-password.directive";
import { ServiceItemComponent } from './components/service/service-item/service-item.component';
import { AboutServiceDialogComponent } from './components/service/about-service-dialog/about-service-dialog.component';
import {PageServiceListComponent} from "@core/pages/page-service/page-service-list/page-service-list.component";
import { PagePromotionServiceListComponent } from './pages/page-promotion-service/page-promotion-service-list/page-promotion-service-list.component';
import { ServiceListComponent } from './components/service/service-list/service-list.component';
import { PageServiceSubscriptionCreateComponent } from './pages/page-service/page-service-subscription-create/page-service-subscription-create.component';
import { PageServiceSubscriptionUpdateComponent } from './pages/page-service/page-service-subscription-update/page-service-subscription-update.component';
import { PagePromotionServiceCreateComponent } from './pages/page-promotion-service/page-promotion-service-create/page-promotion-service-create.component';
import { PagePromotionServiceUpdateComponent } from './pages/page-promotion-service/page-promotion-service-update/page-promotion-service-update.component';
import { PageServiceSubscriptionPaymentResponseComponent } from './pages/page-service/page-service-subscription-payment-response/page-service-subscription-payment-response.component';
import { PageCardCreateComponent } from './pages/page-card/page-card-create/page-card-create.component';
import { CardFormComponent } from './components/card/card-form/card-form.component';
import { PageCardUpdateComponent } from './pages/page-card/page-card-update/page-card-update.component';
import { PageCardPaymentResponseComponent } from './pages/page-card/page-card-payment/page-card-payment-response/page-card-payment-response.component';
import { ServiceSubscriptionDeleteDialogComponent } from './components/service/service-subscription-delete-dialog/service-subscription-delete-dialog.component';
import { PageServiceGiftVoucherComponent } from "@core/pages/page-service/page-service-gift-voucher-create/page-service-gift-voucher.component";
import { PageTransporterListComponent } from './pages/page-transporter/page-transporter-list/page-transporter-list.component';
import { PageTransporterCreateComponent } from './pages/page-transporter/page-transporter-create/page-transporter-create.component';
import { PageTransporterUpdateComponent } from './pages/page-transporter/page-transporter-update/page-transporter-update.component';
import { PageTemplateGiftVoucherListComponent } from './pages/page-template-gift-voucher/page-template-gift-voucher-list/page-template-gift-voucher-list.component';
import { PageTemplateGiftVoucherCreateComponent } from './pages/page-template-gift-voucher/page-template-gift-voucher-create/page-template-gift-voucher-create.component';
import { PageTemplateGiftVoucherUpdateComponent } from './pages/page-template-gift-voucher/page-template-gift-voucher-update/page-template-gift-voucher-update.component';
import { GiftVoucherConfigurationFormComponent } from './components/gift-voucher/gift-voucher-configuration-form/gift-voucher-configuration-form.component';
import {GiftVoucherPictureSourcePipe} from "@core/shared/pipes/gift-voucher-picture-source.pipe";
import { TemplateGiftVoucherDialogComponent } from './components/gift-voucher/template-gift-voucher-dialog/template-gift-voucher-dialog.component';
import { TemplateGiftVoucherFormComponent } from './components/gift-voucher/template-gift-voucher-form/template-gift-voucher-form.component';
import { OfferGiftVoucherRequestCreateComponent } from './components/offer/offer-gift-voucher-request/offer-gift-voucher-request-create/offer-gift-voucher-request-create.component';
import {OfferTransporterPictureSource} from "@core/shared/pipes/offer-transporter-picture-source";
import { OfferGiftVoucherRequestCompositionComponent } from "@core/components/offer/offer-gift-voucher-request/offer-gift-voucher-composition/offer-gift-voucher-request-composition.component";
import { OfferGiftVoucherRequestConfigurationComponent } from "@core/components/offer/offer-gift-voucher-request/offer-gift-voucher-configuration/offer-gift-voucher-request-configuration.component";
import { PageGiftVoucherListComponent } from './pages/page-gift-voucher/page-gift-voucher-list/page-gift-voucher-list.component';
import { PageGiftVoucherReadComponent } from './pages/page-gift-voucher/page-gift-voucher-read/page-gift-voucher-read.component';
import { GiftVoucherUpdateGroupedDialog } from './components/gift-voucher/gift-voucher-update-renewal-dialog/gift-voucher-update-grouped-dialog';
import { OfferUpdateConfirmationDialogComponent } from './components/offer/offer-update/offer-update-confirmation-dialog/offer-update-confirmation-dialog.component';
import { GiftVoucherPeriodCreateComponent } from './components/gift-voucher/gift-voucher-period/gift-voucher-period-create/gift-voucher-period-create.component';
import { PageGiftVoucherPeriodComponent } from './pages/page-gift-voucher/page-gift-voucher-period/page-gift-voucher-period.component';
import { GiftVoucherPeriodCalendarComponent } from './components/gift-voucher/gift-voucher-period/gift-voucher-period-calendar/gift-voucher-period-calendar.component';
import { ServiceRequestDocumentDialogComponent } from "@core/components/service/request-document-dialog/service-request-document-dialog.component";
import { PageCardReadComponent } from './pages/page-card/page-card-read/page-card-read.component';
import { InvoiceDownloadDialogComponent } from './components/account/invoice/invoice-download-dialog/invoice-download-dialog.component';
import { OfferLocationProviderComponent } from "@core/components/offer/offer-location/offer-location-provider/offer-location-provider.component";
import { AdditionalWebsiteConfigurationFormComponent } from './components/additional-website/additional-website-configuration-form/additional-website-configuration-form.component';
import { ToggleBooleanFilterComponent } from './components/filter/toggle-boolean-filter/toggle-boolean-filter.component';
import { PageAvailabilityOfferListComponent } from './pages/page-availability/page-availability-offer/page-availability-offer-list/page-availability-offer-list.component';
import { SearchAutocompleteComponent } from './components/search/search-autocomplete/search-autocomplete.component';
import { PageAvailabilityOfferReadComponent } from './pages/page-availability/page-availability-offer/page-availability-offer-read/page-availability-offer-read.component';
import { PageAvailabilityOfferUpdateComponent } from './pages/page-availability/page-availability-offer/page-availability-offer-update/page-availability-offer-update.component';
import { OfferDateReadComponent } from './components/offer/offer-date/offer-date-read/offer-date-read.component';
import { OfferDateUpdateComponent } from './components/offer/offer-date/offer-date-update/offer-date-update.component';
import { OfferAvailabilityCalendarComponent } from './components/offer/offer-availability/offer-availability-calendar/offer-availability-calendar.component';
import { OfferDateCreateComponent } from './components/offer/offer-date/offer-date-create/offer-date-create.component';
import { OfferBookingSummaryReadComponent } from './components/offer/offer-booking/offer-booking-summary/offer-booking-summary-read/offer-booking-summary-read.component';
import { FormatAgePipe } from './shared/pipes/format-age.pipe';
import { IntlTelInputComponent } from './components/intl/intl-tel/intl-tel-input/intl-tel-input.component';
import { OfferRestrictedSocietyCollectionSelectComponent } from './components/offer/offer-restricted-society/offer-restricted-society-collection/offer-restricted-society-collection-select/offer-restricted-society-collection-select.component';
import { PromotionPartnerSocietyCollectionSelectComponent } from './components/promotion/promotion-partner-society/promotion-partner-society-collection/promotion-partner-society-collection-select/promotion-partner-society-collection-select.component';
import { OfferTranslationFailedDialogComponent } from './components/offer/offer-translation/offer-translation-failed/offer-translation-failed-dialog/offer-translation-failed-dialog.component';
import { PageNetworkOfferCreatorListComponent } from './pages/page-network/page-network-offer-creator/page-network-offer-creator-list/page-network-offer-creator-list.component';
import { PageNetworkOfferCreatorCreateComponent } from './pages/page-network/page-network-offer-creator/page-network-offer-creator-create/page-network-offer-creator-create.component';
import { PageNetworkOfferCreatorUpdateComponent } from './pages/page-network/page-network-offer-creator/page-network-offer-creator-update/page-network-offer-creator-update.component';
import { NetworkOfferCreatorCollectionSelectComponent } from './components/network/network-offer-creator/network-offer-creator-collection/network-offer-creator-collection-select/network-offer-creator-collection-select.component';
import { NetworkOfferCreatorSocietyCollectionSelectComponent } from './components/network/network-offer-creator/network-offer-creator-society/network-offer-creator-society-collection/network-offer-creator-society-collection-select/network-offer-creator-society-collection-select.component';
import { NgPipesModule } from "ngx-pipes";
import { PageOfferPermanentOptionFormComponent } from './pages/page-offer/page-offer-permanent-option/page-offer-permanent-option-form/page-offer-permanent-option-form.component';
import { OfferPermanentOptionOfferSearchComponent } from './components/offer/offer-permanent-option/offer-permanent-option-offer-search/offer-permanent-option-offer-search.component';
import {FileSourcePipe} from "@core/shared/pipes/file-source.pipe";
import { SelectSearchComponent } from './components/select/select-search/select-search.component';
import { PageRegistrationAffiliationListComponent } from "@core/pages/page-registration-affiliation/page-registration-affiliation-list/page-registration-affiliation-list.component";
import { PageRegistrationAffiliationCreateComponent } from "@core/pages/page-registration-affiliation/page-registration-affiliation-create/page-registration-affiliation-create.component";
import { PageRegistrationAffiliationUpdateComponent } from "@core/pages/page-registration-affiliation/page-registration-affiliation-update/page-registration-affiliation-update.component";
import {TreeModule} from "@ali-hm/angular-tree-component";
import { PageOfferCreatorListComponent } from './pages/page-offer/page-offer-creator/page-offer-creator-list/page-offer-creator-list.component';
import { OfferCreatorCardComponent } from './components/offer-creator/offer-creator-card/offer-creator-card.component';
import { TranslationFromQuantityPipe } from './shared/pipes/translation-from-quantity.pipe';
import { OfferCreatorFilterComponent } from './components/offer-creator/offer-creator-filter/offer-creator-filter.component';
import { PageOfferCreatorReadComponent } from './pages/page-offer/page-offer-creator/page-offer-creator-read/page-offer-creator-read.component';
import { OfferCreatorPictureSourcePipe } from './shared/pipes/offer-creator-picture-source.pipe';
import { OfferCreatorPictureGalleryDialogComponent } from './components/offer-creator/offer-creator-picture/offer-creator-picture-gallery/offer-creator-picture-gallery-dialog/offer-creator-picture-gallery-dialog.component';
import { OfferCreatorPictureGalleryComponent } from './components/offer-creator/offer-creator-picture/offer-creator-picture-gallery/offer-creator-picture-gallery.component';
import { OfferCreatorItemComponent } from './components/offer-creator/offer-creator-item/offer-creator-item.component';
import { PageQuotationCreateComponent } from './pages/page-quotation/page-quotation-create/page-quotation-create.component';
import { OfferCreatorSearchComponent } from './components/offer-creator/offer-creator-search/offer-creator-search.component';
import { PageQuotationListComponent } from './pages/page-quotation/page-quotation-list/page-quotation-list.component';
import { PageQuotationReadComponent } from './pages/page-quotation/page-quotation-read/page-quotation-read.component';

@NgModule({
    declarations: [
        HeaderComponent,
        PageNotFoundComponent,
        PageSigninComponent,
        PageDashboardComponent,
        PageUserListComponent,
        PageUserCreateComponent,
        PageUserUpdateComponent,
        LoaderComponent,
        TextFilterComponent,
        SelectFilterComponent,
        PageAdministratorListComponent,
        PageAdministratorCreateComponent,
        PageAdministratorUpdateComponent,
        PageUserValidateAccountComponent,
        TranslationAddComponent,
        SelectLocaleDialogComponent,
        PageInvoiceListComponent,
        CustomDatePipe,
        ActivationPipe,
        DateIntervalFilterComponent,
        SelectArrayFilterComponent,
        SelectArrayMultipleFilterComponent,
        PageSubscriptionListComponent,
        LocationComponent,
        PageUserForgetPasswordComponent,
        PageUserResetPasswordComponent,
        PageUserAccountComponent,
        PageAccountLayoutComponent,
        AddCustomerTypologyDialogComponent,
        PageCollaboratorListComponent,
        PageOfferAttributeListComponent,
        PageChannelRestrictedListComponent,
        PageCollaboratorCreateComponent,
        PageCollaboratorUpdateComponent,
        PageOfferAttributeCreateComponent,
        PageOfferAttributeUpdateComponent,
        PageOfferAttributeListComponent,
        PageChannelRestrictedListComponent,
        PageLicenceUpdateComponent,
        TermsAndConditionsComponent,
        PageOfferCreateComponent,
        PageOfferUpdateComponent,
        PageOfferReadComponent,
        OfferPeriodCreateComponent,
        OfferPriceFormComponent,
        OfferLocationFormComponent,
        OfferPeriodDeleteComponent,
        OfferPeriodDeleteDialogComponent,
        OfferPresentialFormComponent,
        PageOfferListComponent,
        OfferPresentialFormComponent,
        OfferInterestCreateComponent,
        OfferNoIncludedCreateComponent,
        OfferIncludedCreateComponent,
        OfferProviderCreateComponent,
        OfferMapComponent,
        OfferFilterComponent,
        OfferFilterPanelComponent,
        OfferCardComponent,
        OfferListComponent,
        OfferCatalogCreateComponent,
        PageChannelCreateComponent,
        PageChannelListComponent,
        OfferSearchComponent,
        PageChannelUpdateComponent,
        PageChannelReadComponent,
        OfferAttributesFormComponent,
        OfferAccessRequestCreateComponent,
        OfferAccessRequestCreateDialogComponent,
        OfferItemComponent,
        OfferPictureSourcePipe,
        OfferProgramPictureSourcePipe,
        OfferPictureGalleryComponent,
        OfferPictureGalleryDialogComponent,
        ImageSourcePipe,
        FileSourcePipe,
        OfferDateEngineComponent,
        OfferProgramsFormComponent,
        SelectBooleanFilterComponent,
        CheckboxBooleanFilterComponent,
        OfferAccessRequestMultipleCreateDialogComponent,
        OfferAccessRequestMultipleCreateComponent,
        PageDistributorApprovalListComponent,
        OfferCatalogRequestViewComponent,
        OfferCatalogRequestViewDialogComponent,
        OfferCatalogRequestRevokeDialogComponent,
        PageSocietyDistributorListComponent,
        PageSocietyDistributorUpdateComponent,
        OfferBookingRequestCreateComponent,
        PageMenuCreateComponent,
        PageMenuListComponent,
        PageMenuUpdateComponent,
        PageCreateComponent,
        PageListComponent,
        PageUpdateComponent,
        PageReadComponent,
        CustomerFormCreateComponent,
        PageUpdateComponent,
        PageCategoryCreateComponent,
        PageCategoryListComponent,
        PageCategoryUpdateComponent,
        PageCategoryReadComponent,
        CmsButtonComponent,
        CmsTextComponent,
        CmsFileComponent,
        CmsIframeComponent,
        CmsVideoComponent,
        CmsImageComponent,
        CmsSlideshowComponent,
        PageOfferMarkupListComponent,
        MarkupUpdateGroupedDialogComponent,
        PageOfferGroupNetworkProviderListComponent,
        PageOfferGroupNetworkProviderCreateComponent,
        PageOfferGroupNetworkProviderUpdateComponent,
        OfferNetworkProviderCreateComponent,
        PageBookingReadComponent,
        PageBookingListComponent,
        PageOfferMarkupCatalogListComponent,
        PagePromotionListComponent,
        PagePromotionCreateComponent,
        PagePromotionUpdateComponent,
        PageReferenceReadComponent,
        PromotionPeriodCalendarComponent,
        PromotionPeriodCreateComponent,
        PromotionPeriodCreateDialogComponent,
        PromotionPeriodDeleteDialogComponent,
        PromotionPeriodDeleteComponent,
        PageOfferOptionListComponent,
        PageOfferOptionCreateComponent,
        PageOfferOptionUpdateComponent,
        OfferOptionPresentialFormComponent,
        OfferOptionPeriodCalendarComponent,
        OfferOptionPeriodCreateComponent,
        OfferOptionPeriodCreateDialogComponent,
        OfferOptionPeriodDeleteComponent,
        OfferOptionPeriodDeleteDialogComponent,
        SubscriptionDialogComponent,
        PageOfferPermanentOptionListComponent,
        SubscriptionFormComponent,
        PageSubscriptionPaymentComponent,
        NumberCardDirective,
        CvvCardDirective,
        DateCardDirective,
        PageSubscriptionPaymentResponseComponent,
        PagePromotionSubscriptionReadComponent,
        PagePromotionSubscriptionCreateComponent,
        PagePromotionSubscriptionListComponent,
        InputTextStripTagsDirective,
        ParsePricePipe,
        InputTextParsePriceDirective,
        InputTextParseMarkupDirective,
        InputCapitalizeDirective,
        InputTextCountLimitDirective,
        ShowHidePasswordDirective,
        SafeHtmlPipe,
        PageSocietyProviderListComponent,
        PageSocietyProviderCreateDialogComponent,
        PageSocietyProviderSendRequestDialogComponent,
        PageSocietyProviderReadComponent,
        GooglePlacesLocationComponent,
        RoleAdvantageComponent,
        IntroductionDialogComponent,
        LocaleSwitchComponent,
        TimezoneSelectComponent,
        ConditionOfUseDialogComponent,
        ChannelOfferCardPreviewComponent,
        ChannelOfferViewPreviewComponent,
        ServiceItemComponent,
        AboutServiceDialogComponent,
        PageServiceListComponent,
        ServiceListComponent,
        PageServiceSubscriptionCreateComponent,
        PageServiceSubscriptionUpdateComponent,
        PagePromotionServiceListComponent,
        PagePromotionServiceCreateComponent,
        PagePromotionServiceUpdateComponent,
        PageServiceSubscriptionPaymentResponseComponent,
        PageCardCreateComponent,
        CardFormComponent,
        PageCardUpdateComponent,
        PageCardPaymentResponseComponent,
        ServiceSubscriptionDeleteDialogComponent,
        PageServiceGiftVoucherComponent,
        PageTransporterListComponent,
        PageTransporterCreateComponent,
        PageTransporterUpdateComponent,
        PageTemplateGiftVoucherListComponent,
        PageTemplateGiftVoucherCreateComponent,
        PageTemplateGiftVoucherUpdateComponent,
        GiftVoucherConfigurationFormComponent,
        GiftVoucherPictureSourcePipe,
        TemplateGiftVoucherDialogComponent,
        TemplateGiftVoucherFormComponent,
        OfferGiftVoucherRequestCreateComponent,
        OfferGiftVoucherRequestCompositionComponent,
        OfferGiftVoucherRequestConfigurationComponent,
        OfferTransporterPictureSource,
        PageGiftVoucherListComponent,
        PageGiftVoucherReadComponent,
        GiftVoucherUpdateGroupedDialog,
        OfferUpdateConfirmationDialogComponent,
        GiftVoucherPeriodCreateComponent,
        PageGiftVoucherPeriodComponent,
        GiftVoucherPeriodCalendarComponent,
        OfferUpdateConfirmationDialogComponent,
        ServiceRequestDocumentDialogComponent,
        PageCardReadComponent,
        OfferLocationProviderComponent,
        AdditionalWebsiteConfigurationFormComponent,
        InvoiceDownloadDialogComponent,
        ToggleBooleanFilterComponent,
        PageAvailabilityOfferListComponent,
        SearchAutocompleteComponent,
        PageAvailabilityOfferReadComponent,
        PageAvailabilityOfferUpdateComponent,
        OfferDateReadComponent,
        OfferDateUpdateComponent,
        OfferAvailabilityCalendarComponent,
        OfferDateCreateComponent,
        OfferBookingSummaryReadComponent,
        FormatAgePipe,
        IntlTelInputComponent,
        OfferRestrictedSocietyCollectionSelectComponent,
        PromotionPartnerSocietyCollectionSelectComponent,
        OfferTranslationFailedDialogComponent,
        PageNetworkOfferCreatorListComponent,
        PageNetworkOfferCreatorCreateComponent,
        PageNetworkOfferCreatorUpdateComponent,
        NetworkOfferCreatorCollectionSelectComponent,
        NetworkOfferCreatorSocietyCollectionSelectComponent,
        PageOfferPermanentOptionFormComponent,
        OfferPermanentOptionOfferSearchComponent,
        SelectSearchComponent,
        PageRegistrationAffiliationListComponent,
        PageRegistrationAffiliationCreateComponent,
        PageRegistrationAffiliationUpdateComponent,
        PageOfferCreatorListComponent,
        OfferCreatorCardComponent,
        TranslationFromQuantityPipe,
        OfferCreatorFilterComponent,
        PageOfferCreatorReadComponent,
        OfferCreatorPictureSourcePipe,
        OfferCreatorPictureGalleryDialogComponent,
        OfferCreatorPictureGalleryComponent,
        OfferCreatorItemComponent,
        PageQuotationCreateComponent,
        OfferCreatorSearchComponent,
        PageQuotationListComponent,
        PageQuotationReadComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MaterialModule,
        LibModule,
        TranslateModule,
        FullCalendarModule,
        NgxMatSelectSearchModule,
        LeafletModule,
        LeafletMarkerClusterModule,
        MomentModule,
        TreeModule,
        ColorPickerModule,
        NgxMaterialTimepickerModule,
        ClipboardModule,
        NgxIntlTelInputModule,
        DragDropModule,
        NgPipesModule
    ],
    providers: [
        AuthenticationGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        {
            provide: MatPaginatorIntl,
            useClass: CustomPaginator
        }
    ],
    exports: [
        NavigationModule,
        HeaderComponent,
        LoaderComponent
    ]
})
export class CoreModule {

    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {

        if (parentModule) {

            throw new Error('Le CoreModule est déjà chargé.');
        }
    }
}
