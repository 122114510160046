import {AfterViewInit, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormService} from "@core/shared/services/form.service";
import {MatLegacyTabGroup as MatTabGroup} from "@angular/material/legacy-tabs";
import {AbstractControl, FormBuilder, FormControl, FormGroup, UntypedFormControl, Validators} from "@angular/forms";
import {FormTabValidationItem, OfferFormTabValidationService} from "@core/shared/services/form/form-tab-validation.service";
import {ActivatedRoute, Router} from "@angular/router";
import {OfferCreatorSearchService} from "@core/shared/services/offer-creator/offer-creator-search.service";
import {Society} from "@core/shared/models/society";
import {map, tap} from "rxjs/operators";
import {Currency} from "@core/shared/models/currency";
import {Observable, of} from "rxjs";
import {CurrencyService} from "@core/shared/services/currency.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {CustomerTypology} from "@core/shared/models/customer-typology";
import {User} from "@core/shared/models/user";
import {UserService} from "@core/shared/services/user.service";
import {uniqueArrayOfObjectsByProperty} from "@core/shared/utils/arrays_utils";
import {OfferAttribute} from "@core/shared/models/offer-attribute";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";
import {OfferAttributeService} from "@core/shared/services/offer-attribute.service";
import {Moment} from "moment/moment";
import {QuotationFlexibilityService} from "@core/shared/services/quotation/quotation-flexibility.service";
import {QuotationFlexibility} from "@core/shared/models/quotation/quotation-flexibility";
import {OfferDurationService} from "@core/shared/services/offer/offer-duration.service";
import {OfferDuration} from "@core/shared/models/offer/offer-duration";
import {REGEX_ONLY_NUMBER} from "@core/shared/models/regex";
import {Country} from "@core/shared/models/country";
import {CountryService} from "@core/shared/services/country.service";
import {Address} from "@core/shared/models/address";
import moment from "moment";
import {DATE_FORMAT, SUPPORTED_LOCALES} from "@app/data";
import {OfferAttributeTypeTagType} from "@core/shared/models/offer-attribute-type";
import {Quotation} from "@core/shared/models/quotation";
import {QuotationService} from "@core/shared/services/quotation.service";
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmDialogData} from "@lib/confirm-dialog/confirm-dialog";
import {MatLegacyOption} from "@angular/material/legacy-core";

interface AttributeObservableData {

    tag: OfferAttributeTypeTagType;

    observable: Observable<OfferAttribute[]>;
}

type TemplateView = ('form' | 'confirmation');

@Component({
    selector: 'app-core-page-quotation-create',
    templateUrl: './page-quotation-create.component.html',
    styleUrls: ['./page-quotation-create.component.scss'],
    providers: [
        FormService,
        OfferFormTabValidationService,
        OfferCreatorSearchService
    ]
})
export class PageQuotationCreateComponent implements OnInit, AfterViewInit {

    @ViewChild('tabGroup', {static: false}) tabGroup: MatTabGroup;

    @ViewChild('offerCreators', {static: false}) offerCreatorsRef: TemplateRef<any>;

    @ViewChild('formData', {static: false}) formDataRef: TemplateRef<any>;

    @ViewChild('summary', {static: false}) summaryRef: TemplateRef<any>;

    public readonly moment = moment;

    public activeViews: TemplateView[] = ['form'];

    public currentUser: User;

    public origin: { key: string, backLabel: string } = null;

    public tabItems: { tag: string, label: string, template: TemplateRef<any> }[] = [];

    public currencies$: Observable<Currency[]>;

    public flexibilities$: Observable<QuotationFlexibility[]>;

    public durations$: Observable<OfferDuration[]>;

    public countries$: Observable<Country[]>;

    public attributeObservables$: AttributeObservableData[];

    public regions: OfferAttribute[] = [];

    public availableCustomerTypologies: CustomerTypology[] = [];

    public availableRegions: OfferAttribute[] = [];

    public dateStart: Moment;

    public dateEnd: Moment;

    public maxClosedAt: Date;

    public maxLengthComment: number = 500;

    public spokenLanguages: string[] = SUPPORTED_LOCALES;

    public otherSpokenLanguagesIdentifier: string = '_OTHER_';

    public minStayDateEnd: Moment = null;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _formBuilder: FormBuilder,
        private _userService: UserService,
        private _quotationFlexibilityService: QuotationFlexibilityService,
        private _currencyService: CurrencyService,
        private _countryService: CountryService,
        private _offerAttributeService: OfferAttributeService,
        private _offerDurationService: OfferDurationService,
        private _quotationService: QuotationService,
        public formService: FormService,
        public offerCreatorSearchService: OfferCreatorSearchService,
        public offerFormTabValidationService: OfferFormTabValidationService,
        public translationService: TranslationService
    ) {}

    ngOnInit() {

        this.currentUser = this._userService.currentUser.getValue();

        this.maxClosedAt = moment().add(60, 'days').toDate();

        this._configureSearchService();

        this._initOrigin();

        this._initRegions();

        this._initFlexibilities();

        this._initDurations();

        this._initCurrencies();

        this._initCountries();

        this._initAttributes();

        this._initForm();

        this._initEvents();
    }

    ngAfterViewInit() {

        this._initTabItems();

        this._initFormTabValidation();

        this._changeDetectorRef.detectChanges();
    }

    private _configureSearchService(): void {

        this.offerCreatorSearchService.selectOfferCreatorEnabled = true;

        this.offerCreatorSearchService.selectOfferCreatorAllowed = true;

        this.offerCreatorSearchService.maxOfferCreatorSelectionAllowed = 5;

        this.offerCreatorSearchService.additionalFilterParams$.next([
           `id[andnin][]=${this.currentUser.society.id}`
        ]);
    }

    private _initOrigin(): void {

        this.origin = this._activatedRoute.snapshot.queryParams['origin'] ? {
            key: this._activatedRoute.snapshot.queryParams['origin'],
            backLabel: `quotation.creation.origin.${ this._activatedRoute.snapshot.queryParams['origin'] }.value`
        } : null;
    }

    private _initTabItems(): void {

        this.tabItems = [
            {
                tag: 'offerCreators',
                label: 'offerCreator.plural.selection.value',
                template: this.offerCreatorsRef
            },
            {
                tag: 'formData',
                label: 'form.value',
                template: this.formDataRef
            },
            {
                tag: 'summary',
                label: 'summaryAndSend.value',
                template: this.summaryRef
            }
        ];
    }

    private _initFormTabValidation(): void {

        this.offerFormTabValidationService.init(this.tabGroup, this.form, this.formTabValidationItemsCallback);
    }

    private _initRegions(): void {

        const typeFilter: ArrayFilterField = new ArrayFilterField('type.tag', 'andin', 'region');

        const params: string[] = [
            `sort[label]=ASC`,
            typeFilter.serialize
        ];

        this._offerAttributeService.getItemsAPI(params).subscribe((regions: OfferAttribute[]): void => {

            this.regions = regions;
        });
    }

    private _initFlexibilities(): void {

        this.flexibilities$ = this._quotationFlexibilityService.getItemsAPI();
    }

    private _initDurations(): void {

        const params: string[] = [
            `sort[position]=ASC`
        ];

        this.durations$ = this._offerDurationService.getItemsAPI(params);
    }

    private _initCurrencies(): void {

        this.currencies$ = this._currencyService.getItemsAPI().pipe(
            tap((currencies: Currency[]): void => {

                const defaultCurrency: Currency = currencies.find((currency: Currency): boolean => {

                    return currency.code === 'EUR';
                });

                this.pricingControl.get('currency').patchValue(defaultCurrency);
            }),
            map((currencies: Currency[]): Currency[] => {

                return currencies.filter((currency: Currency): boolean => {

                    return !['TWD','AED'].includes(currency.code);
                });
            })
        );
    }

    private _initCountries(): void {

        this.countries$ = this._countryService.getItemsAPI().pipe(
            tap((countries: Country[]): void => {

                const address: Address = this.currentUser.society.addresses.find((address: Address): boolean => {

                    return address.type === 'mailing';
                });

                if(!address || !address.country){

                    return;
                }

                const defaultCountry: Country = countries.find((country: Country): boolean => {

                    return country.code === address.country;
                });

                this.customerControl.get('country').patchValue(defaultCountry);
            })
        );
    }

    private _initAttributes(): void {

        this.attributeObservables$ = [];

        this.attributeObservables$.push(...this.attributeTypeTags.map((tag: OfferAttributeTypeTagType): AttributeObservableData => {

            const typeFilter: ArrayFilterField = new ArrayFilterField('type.tag', 'andin', tag);

            return {
                tag: tag,
                observable: this._offerAttributeService.getItemsAPI([
                    typeFilter.serialize
                ])
            };
        }));
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            offerCreators: [[], [(control: UntypedFormControl) => {

                if (control.value.length < 1) {

                    return {
                        'minLengthArray': {
                            valid: false
                        }
                    };
                }

                if (control.value.length > this.offerCreatorSearchService.maxOfferCreatorSelectionAllowed) {

                    return {
                        'maxLengthArray': {
                            valid: false
                        }
                    };
                }

                return null;
            }]],
            ownerReference: [null],
            closedAt: [null, [Validators.required]],
            composition: this._formBuilder.group({
                flexibility: [null, [(control: UntypedFormControl) => {

                    if(!this.form || !this.configurationControl){

                        return null;
                    }

                    const allowGiftVoucherControl: FormControl = this.configurationControl.get('allowGiftVoucher') as FormControl;

                    const allowGiftVoucher: boolean = allowGiftVoucherControl.value;

                    if(!allowGiftVoucher){

                        return (control.value as boolean) ? null : {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }
                    else{

                        return null;
                    }
                }]],
                duration: [null, [Validators.required]],
                dateStart: [null, [(control: UntypedFormControl) => {

                    if(!this.form || !this.configurationControl){

                        return null;
                    }

                    const allowGiftVoucherControl: FormControl = this.configurationControl.get('allowGiftVoucher') as FormControl;

                    const allowGiftVoucher: boolean = allowGiftVoucherControl.value;

                    if(!allowGiftVoucher){

                        return (control.value as boolean) ? null : {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }
                    else{

                        return null;
                    }
                }]],
                dateEnd: [null, [(control: UntypedFormControl) => {

                    if(!this.form || !this.configurationControl){

                        return null;
                    }

                    const allowGiftVoucherControl: FormControl = this.configurationControl.get('allowGiftVoucher') as FormControl;

                    const allowGiftVoucher: boolean = allowGiftVoucherControl.value;

                    if(!allowGiftVoucher){

                        return (control.value as boolean) ? null : {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }
                    else{

                        return null;
                    }
                }]],
                nbAdult: [null, [Validators.pattern(REGEX_ONLY_NUMBER), (control: UntypedFormControl) => {

                    if(!this.form || !this.compositionControl){

                        return null;
                    }

                    const nbChildControl: FormControl = this.compositionControl.get('nbChild') as FormControl;

                    const nbChild: string = nbChildControl.value;

                    if(!nbChild || !nbChild.length){

                        return (control.value as boolean) ? null : {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }
                    else{

                        return null;
                    }
                }]],
                nbChild: [null, [Validators.pattern(REGEX_ONLY_NUMBER), (control: UntypedFormControl) => {

                    if(!this.form || !this.compositionControl){

                        return null;
                    }

                    const nbAdultControl: FormControl = this.compositionControl.get('nbAdult') as FormControl;

                    const nbAdult: string = nbAdultControl.value;

                    if(!nbAdult || !nbAdult.length){

                        return (control.value as boolean) ? null : {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }
                    else{

                        return null;
                    }
                }]],
                childrenAgeComment: [null, [Validators.maxLength(this.maxLengthComment)]]
            }),
            configuration: this._formBuilder.group({
                regions: [[], [(control: UntypedFormControl) => {

                    if (control.value.length < 1) {

                        return {
                            'minLengthArray': {
                                valid: false
                            }
                        };
                    }

                    return null;
                }]],
                regionComment: [null, [Validators.maxLength(this.maxLengthComment)]],
                accommodations: [[], [(control: AbstractControl): { [key: string]: any } => {

                    if (control.value.length >= 1) {

                        return null;
                    }

                    return {
                        'minLengthArray': {
                            valid: false
                        }
                    };
                }]],
                activities: [[]],
                restoration: [null, [Validators.required]],
                restorationComment: [null, [Validators.maxLength(this.maxLengthComment)]],
                customerTypology: [null, [Validators.required]],
                allowGiftVoucher: [false, [(control: UntypedFormControl) => {

                    if(!control.parent){

                        return null;
                    }

                    const customerTypologyControl: FormControl = control.parent.get('customerTypology') as FormControl;

                    if(!customerTypologyControl || !customerTypologyControl.value){

                        return null;
                    }

                    const customerTypology: CustomerTypology = customerTypologyControl.value;

                    if(customerTypology.type === 'I'){

                        return (control.value !== null) ? null : {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }
                    else {

                        return null;
                    }
                }]],
                accommodationsComment: [null, [Validators.maxLength(this.maxLengthComment)]],
                hasInsurance: [false, [Validators.required]],
                insuranceComment: [null, [(control: UntypedFormControl) => {

                    if(!control.parent){

                        return null;
                    }

                    const hasInsuranceControl: FormControl = control.parent.get('hasInsurance') as FormControl;

                    if(!hasInsuranceControl){

                        return null;
                    }

                    const hasInsurance: boolean = hasInsuranceControl.value;

                    if(hasInsurance){

                        return (control.value as boolean) ? null : {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }
                    else{

                        return null;
                    }
                }]]
            }),
            customer: this._formBuilder.group({
                spokenLanguage: [this.currentUser.locale, [Validators.required]],
                otherSpokenLanguages: [null, [(control: FormControl) => {

                    if(!control.parent){

                        return null;
                    }

                    const spokenLanguage: string = control.parent.get('spokenLanguage').value;

                    if(!spokenLanguage || !Boolean(spokenLanguage.length)){

                        return null;
                    }

                    if(spokenLanguage !== this.otherSpokenLanguagesIdentifier){

                        return null;
                    }

                    if(!!control.value && Boolean(control.value.length)){

                        return null;
                    }

                    return {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]],
                comment: [null, [Validators.required, Validators.maxLength(this.maxLengthComment)]],
                country: [null, [Validators.required]]
            }),
            pricing: this._formBuilder.group({
                currency: [null, [Validators.required]],
                budget: [null, [Validators.required, Validators.pattern(REGEX_ONLY_NUMBER)]],
                comment: [null, [Validators.maxLength(this.maxLengthComment)]]
            })
        });

        this.formService.submitCallback = (): void => {

            if(this.form.invalid){

                return;
            }

            const formValue = {...this.form.getRawValue()};

            const data: object = Object.assign(formValue, {
                offerCreators: (formValue.offerCreators as Society[]).map((offerCreator: Society): { offerCreator: Society } => {

                    return {
                        offerCreator: offerCreator
                    };
                }),
                closedAt: (formValue.closedAt as Moment).format(DATE_FORMAT),
                composition: Object.assign(formValue.composition, {
                    dateStart: formValue.composition.dateStart ? (formValue.composition.dateStart as Moment).format(DATE_FORMAT) : null,
                    dateEnd: formValue.composition.dateEnd ? (formValue.composition.dateEnd as Moment).format(DATE_FORMAT) : null,
                    nbAdult: formValue.composition.nbAdult ? parseInt(formValue.composition.nbAdult) : 0,
                    nbChild: formValue.composition.nbChild ? parseInt(formValue.composition.nbChild) : 0
                }),
                configuration: Object.assign(formValue.configuration, {
                    accommodations: (formValue.configuration.accommodations as OfferAttribute[]).filter((item: OfferAttribute): boolean => {

                        return item.id !== null;
                    }),
                    restoration: formValue.configuration.restoration.id ? formValue.configuration.restoration : null
                }),
                customer: Object.assign(formValue.customer, {
                    country: (formValue.customer.country as Country).code
                })
            });

            this._quotationService.createItemAPI(data).subscribe((): void => {

                this.setActiveViews(['confirmation']);
            });
        };
    }

    private _initEvents(): void {

        this.offerCreatorSearchService.selectedOfferCreators$.subscribe((selectedItems: Society[]): void => {

            this.form.get('offerCreators').patchValue(selectedItems);

            this.offerCreatorSearchService.selectOfferCreatorAllowed = (selectedItems.length < this.offerCreatorSearchService.maxOfferCreatorSelectionAllowed);

            this._updateCustomerTypologies();

            this._updateRegions();
        });

        this.configurationControl.get('customerTypology').valueChanges.subscribe((): void => {

            this.configurationControl.get('allowGiftVoucher').updateValueAndValidity();

            this.configurationControl.get('allowGiftVoucher').markAsTouched();
        });

        this.configurationControl.get('hasInsurance').valueChanges.subscribe((): void => {

            this.configurationControl.get('insuranceComment').patchValue(null);

            this.configurationControl.get('insuranceComment').updateValueAndValidity();

            this.configurationControl.get('insuranceComment').markAsTouched();
        });

        this.configurationControl.get('allowGiftVoucher').valueChanges.subscribe((): void => {

            this.compositionControl.get('dateStart').patchValue(null);

            this.compositionControl.get('dateStart').updateValueAndValidity();

            this.compositionControl.get('dateStart').markAsTouched();

            this.compositionControl.get('dateEnd').patchValue(null);

            this.compositionControl.get('dateEnd').updateValueAndValidity();

            this.compositionControl.get('dateEnd').markAsTouched();

            this.compositionControl.get('flexibility').patchValue(null);

            this.compositionControl.get('flexibility').updateValueAndValidity();

            this.compositionControl.get('flexibility').markAsTouched();
        });

        this.compositionControl.get('dateStart').valueChanges.subscribe((dateStart: Moment): void => {

            this.compositionControl.get('dateEnd').patchValue(null);

            this.minStayDateEnd = dateStart;
        });

        this.compositionControl.get('nbAdult').valueChanges.subscribe((): void => {

            this.compositionControl.get('nbChild').updateValueAndValidity({onlySelf: true, emitEvent: false});
        });

        this.compositionControl.get('nbChild').valueChanges.subscribe((): void => {

            this.compositionControl.get('nbAdult').updateValueAndValidity({onlySelf: true, emitEvent: false});
        });

        this.customerControl.get('spokenLanguage').valueChanges.subscribe((): void => {

            this.customerControl.get('otherSpokenLanguages').patchValue(null);

            this.customerControl.get('otherSpokenLanguages').updateValueAndValidity();

            this.customerControl.get('otherSpokenLanguages').markAsTouched();
        });
    }

    private _updateCustomerTypologies(): void {

        // Réinitialisation de la cible clientèle sélectionnée

        this.configurationControl.get('customerTypology').patchValue(null,{ onlySelf: true, emitEvent: false });

        // Récupération des cibles clientèles des créateurs d'offres

        const offerCreatorCustomerTypologies: CustomerTypology[] = [];

        (this.form.get('offerCreators').value as Society[]).forEach((item: Society): void => {

            offerCreatorCustomerTypologies.push(...item.details.customerTypologies);
        });

        this.availableCustomerTypologies = uniqueArrayOfObjectsByProperty(offerCreatorCustomerTypologies, 'id');
    }

    private _updateRegions(): void {

        // Réinitialisation des régions sélectionnées

        this.configurationControl.get('regions').patchValue([]);

        // Récupération des régions des créateurs d'offres

        const isAllRegions: boolean = (this.form.get('offerCreators').value as Society[]).some((item: Society): boolean => {

            return item.details.allRegions;
        });

        if(isAllRegions){

            this.availableRegions = [...this.regions];
        }
        else{

            const offerCreatorRegions: OfferAttribute[] = [];

            (this.form.get('offerCreators').value as Society[]).forEach((item: Society): void => {

                offerCreatorRegions.push(...item.details.regions);
            });

            this.availableRegions = uniqueArrayOfObjectsByProperty(offerCreatorRegions, 'id');
        }
    }

    public compareById(a: { id: number }, b: { id: number }): boolean {

        if(!a || !b){

            return false;
        }

        return a.id === b.id;
    }

    public redirectToOrigin(): void {

        switch (this.origin.key){

            case 'catalog':

                this._router.navigate(['account/offer/list/catalog']);

                break;

            case 'offerCreatorList':

                this._router.navigate(['account/offer-creator/list']);

                break;
        }
    }

    public resetOfferCreatorSelection(): void {

        this.offerCreatorSearchService.selectedOfferCreators$.next([]);
    }

    public getAttributeObservable(tag: OfferAttributeTypeTagType): Observable<OfferAttribute[]> {

        const match: AttributeObservableData = this.attributeObservables$.find((attributeObservable: AttributeObservableData ): boolean => {

            return attributeObservable.tag === tag;
        });

        return match ? match.observable : of([]);
    }

    public parseAttributes(attributes: OfferAttribute[]): string {

        return attributes.map((attribute: OfferAttribute): string => {

            if(attribute.id === null){

                return this._translateService.instant('withoutPreference.value');
            }

            return this.translationService.getFallbackTranslation(attribute.translations).label;

        }).join(', ');
    }

    public isActiveView(view: TemplateView): boolean{

        return this.activeViews.includes(view);
    }

    public setActiveViews(activeViews: TemplateView[]): void {

        this.activeViews = activeViews;
    }

    public redirectToList(): void {

        this._router.navigate(['account/quotation/list']);
    }

    public openLeaveWithoutSubmitDialog(): void {

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: this._translateService.instant('quotation.leaveWithoutSubmit.value'),
                content: this._translateService.instant('quotation.leaveWithoutSubmit.description.value')
            } as ConfirmDialogData
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this.redirectToOrigin();
        });
    }

    public handleAccommodation(option: MatLegacyOption): void {

        const isNoneAccommodationOption: boolean = option.value.id === null;

        if(isNoneAccommodationOption){

            this.configurationControl.get('accommodations').patchValue(option.selected ? [{id: null}] : []);
        }
        else{

            const currentAccommodations: { id: number }[] = [...(this.configurationControl.get('accommodations').value as OfferAttribute[])].filter((item: OfferAttribute): boolean => {

                return item.id !== null;
            });

            this.configurationControl.get('accommodations').patchValue(currentAccommodations);
        }
    }

    get form(): FormGroup {

        return this.formService.form;
    }

    get configurationControl(): FormGroup {

        return this.form.get('configuration') as FormGroup;
    }

    get compositionControl(): FormGroup {

        return this.form.get('composition') as FormGroup;
    }

    get customerControl(): FormGroup {

        return this.form.get('customer') as FormGroup;
    }

    get pricingControl(): FormGroup {

        return this.form.get('pricing') as FormGroup;
    }

    get formTabValidationItemsCallback(): () => FormTabValidationItem[] {

        return (): FormTabValidationItem[] => {

            const items: Partial<FormTabValidationItem>[] = [];

            // Créateurs d'offres

            items.push({
                tag: 'offerCreators',
                controls: [
                    this.form.get('offerCreators')
                ]
            });

            // Données du formulaire

            items.push({
                tag: 'formData',
                controls: [
                    this.form.get('ownerReference'),
                    this.form.get('closedAt'),
                    this.configurationControl,
                    this.compositionControl,
                    this.customerControl,
                    this.pricingControl
                ]
            });

            // Récapitulatif et envoi

            items.push({
                tag: 'summary',
                controls: []
            });

            return items.map((item: Partial<FormTabValidationItem>, index: number): FormTabValidationItem => {

                return Object.assign(item, {
                    position: index + 1
                } as FormTabValidationItem);
            });
        };
    }

    get hasChild(): boolean {

        const control: FormControl = this.compositionControl.get('nbChild') as FormControl;

        return Boolean(parseInt(control.value));
    }

    get attributeTypeTags(): OfferAttributeTypeTagType[] {

        return ['accommodations', 'restoration', 'activities'];
    }

    get quotation(): Quotation {

        return Object.assign(this.form.getRawValue(), {
            customer: Object.assign(this.customerControl.getRawValue(), {
                spokenLanguage: (this.customerControl.get('spokenLanguage').value === this.otherSpokenLanguagesIdentifier) ? null : this.customerControl.get('spokenLanguage').value
            })
        }) as Quotation;
    }

    get selectedOfferCreators(): Society[] {

        return this.form.get('offerCreators').value as Society[];
    }
}
