import {Component, OnInit} from '@angular/core';
import {OfferCreatorSearchService} from "@core/shared/services/offer-creator/offer-creator-search.service";
import {UserService} from "@core/shared/services/user.service";
import {User} from "@core/shared/models/user";
import {TranslateService} from "@ngx-translate/core";
import {Role} from "@core/shared/models/role";
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {Router} from "@angular/router";
import {Access} from "@core/shared/models/access";

@Component({
    selector: 'app-core-page-offer-creator-list',
    templateUrl: './page-offer-creator-list.component.html',
    styleUrls: ['./page-offer-creator-list.component.scss'],
    providers: [
        OfferCreatorSearchService
    ]
})
export class PageOfferCreatorListComponent implements OnInit {

    public currentUser: User = null;

    constructor(
        private _router: Router,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _offerCreatorSearchService: OfferCreatorSearchService,
        public userService: UserService,
    ) {}

    ngOnInit() {

        this.currentUser = this.userService.currentUser.value;
    }

    public hasOneOfThisRoles(roles: Role[]): boolean {

        return this.userService.hasOneOfThisRoles(this.currentUser, roles);
    }

    public openCreateQuotationDialog(): void {

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: this._translateService.instant('quotation.value'),
                content: `<p>${this._translateService.instant('quotation.question.description.value')}</p>`
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._router.navigate(['account/quotation/create'], { queryParams: { origin: 'offerCreatorList' } });
        });
    }

    get isQuotationCreationAllowed(): boolean {

        const conditions: boolean[] = [
            this.hasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']),
            this.currentUser.accesses.some((access: Access): boolean => {

                return access.tag === 'OFFER_CREATOR_LIST';
            }),
            this.currentUser.accesses.some((access: Access): boolean => {

                return access.tag === 'QUOTATION_CREATE';
            })
        ];

        return conditions.every((value: boolean) => {

            return value === true;
        });
    }

    get totalItems(): number {

        return this._offerCreatorSearchService.totalOfferCreators;
    }
}
