import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Society} from "@core/shared/models/society";

@Component({
    selector: 'app-core-page-offer-creator-read',
    templateUrl: './page-offer-creator-read.component.html',
    styleUrls: ['./page-offer-creator-read.component.scss']
})
export class PageOfferCreatorReadComponent implements OnInit {

    public item: Society;

    public listRedirectionAllowed: boolean = true;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router
    ) {}

    ngOnInit() {

        this._activatedRoute.data.subscribe((data: { offerCreator: Society }): void => {

            this._handleListRedirection();

            this.item = data.offerCreator;
        });
    }

    private _handleListRedirection(): void {

        if(!this._activatedRoute.snapshot.queryParams['list-redirection-allowed']){

            return;
        }

        this.listRedirectionAllowed = Boolean(parseInt(this._activatedRoute.snapshot.queryParams['list-redirection-allowed']));
    }

    public redirectToList(): void {

        this._router.navigate(['account/offer-creator/list']);
    }
}
