<div class="page_offer page_offer_creator">

    <ng-container *ngIf="listRedirectionAllowed">

        <div class="back_to_list">
            <span class="ty-chevron" (click)="redirectToList()">{{ 'offerCreator.list.back.value' | translate }}</span>
        </div>

    </ng-container>

    <app-core-offer-creator-item [item]="item"></app-core-offer-creator-item>
</div>
