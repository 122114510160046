<div class="card_offer card_creator" [ngClass]="{'selected': offerCreatorSearchService.selectOfferCreatorEnabled && offerCreatorSearchService.isSelectedOfferCreator(item)}">
    <div class="row h_full direction_y">
        <div class="col_content card_top">
            <div class="visuel">
                <img [src]="item.logo ? ( item.logo | fileSource | async) : defaultVisualUrl" [alt]="item.name">
                <div class="infos">
                    <div class="upper_left">
                        <span *ngIf="userService.hasOneOfThisRoles(currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']) && (item.details.totalCatalogOffers > 0)" class="has-offer-in-catalog"><span>{{ 'offerCreator.inCatalog.mine.value' | translate }}</span></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col card_bottom">
            <div class="infos_offer">
                <div class="row h_full direction_y">
                    <div class="col infos_wrapper">
                        <div class="infos" [ngClass]="{'closed' : cardClosed}">
                            <div class="row marges">
                                <div class="col y_items_center title">
                                    <div class="row">
                                        <div class="col_12">
                                            <p>{{ item.name }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col_content y_items_start">
                                    <span class="show_more ty-chevron" (click)="cardClosed ? cardClosed = false : cardClosed = true"></span>
                                </div>
                            </div>

                            <ng-container *ngIf="item.details.totalMarketplaceOffers > 0">

                                <div class="row marges">
                                    <div class="col_12">
                                        <p style="font-weight: 500;">

                                            <ng-container *ngIf="userService.hasOneOfThisRoles(currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])">
                                                {{ item.details.totalMarketplaceOffers | translationFromQuantity:'offer.inMarketplace.total.empty.value':'offer.inMarketplace.total.singular.value':'offer.inMarketplace.total.plural.value' }}
                                            </ng-container>
                                            <ng-container *ngIf="userService.hasOneOfThisRoles(currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_FEDERATION', 'ROLE_INSTITUTIONAL'])">
                                                {{ item.details.totalMarketplaceOffers | translationFromQuantity:'offer.inMarketplace.self.total.empty.value' : 'offer.inMarketplace.self.total.singular.value' : 'offer.inMarketplace.self.total.plural.value' }}
                                            </ng-container>

                                        </p>
                                    </div>
                                </div>

                                <ng-container *ngIf="userService.hasOneOfThisRoles(currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])">

                                    <div class="row marges">
                                        <div class="col_12">
                                            <p style="font-weight: 500;">
                                                {{ item.details.totalCatalogOffers | translationFromQuantity: 'offer.inCatalog.total.empty.value' : 'offer.inCatalog.total.singular.value' : 'offer.inCatalog.total.plural.value' }}
                                            </p>
                                        </div>
                                    </div>

                                </ng-container>

                            </ng-container>

                            <div class="infos_sup">

                                <ng-container *ngIf="item.details.customerTypologies.length > 0">

                                    <div class="row">
                                        <div class="col_5">
                                            <p>{{ 'target.plural.value' | translate }}</p>
                                        </div>
                                        <div class="col_7">
                                            <p>{{ parsedTargets }}</p>
                                        </div>
                                    </div>

                                </ng-container>

                                <ng-container *ngIf="item.details.durationTypes.length > 0">

                                    <div class="row">
                                        <div class="col_5">
                                            <p>{{ 'offer.plural.type.plural.value' | translate }}</p>
                                        </div>
                                        <div class="col_7">
                                            <p>{{ parsedOfferTypes }}</p>
                                        </div>
                                    </div>

                                </ng-container>

                                <ng-container *ngIf="item.details.allRegions || (item.details.regions.length > 0)">

                                    <div class="row">
                                        <div class="col_5">
                                            <p>{{ 'destination.plural.value' | translate }}</p>
                                        </div>
                                        <div class="col_7">
                                            <ng-container *ngIf="item.details.allRegions; else regions">
                                                <p>{{ 'france.all.value' | translate }}</p>
                                            </ng-container>
                                            <ng-template #regions>
                                                <p>{{ parsedRegions }}</p>
                                            </ng-template>
                                        </div>
                                    </div>

                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="col_content">
                        <div class="links_offer">

                            <!-- Sélection -->

                            <ng-container *ngIf="offerCreatorSearchService.selectOfferCreatorEnabled">

                                <ng-container *ngIf="offerCreatorSearchService.isSelectedOfferCreator(item)">

                                    <div>
                                        <a (click)="offerCreatorSearchService.unselectOfferCreator(item)" class="creator_selected">
                                            <span>{{'offerCreator.selected' | translate}}</span>
                                        </a>
                                    </div>

                                </ng-container>

                                <ng-container *ngIf="!offerCreatorSearchService.isSelectedOfferCreator(item)">

                                    <div>
                                        <a [ngClass]="{'disabled' : !offerCreatorSearchService.selectOfferCreatorAllowed || !offerCreatorSearchService.isOfferCreatorSelectionAllowed(item, mode)}" [matTooltip]="offerCreatorSearchService.offerCreatorSelectionDisableReason(item, mode)" matTooltipPosition="above" [matTooltipDisabled]="offerCreatorSearchService.selectOfferCreatorAllowed && offerCreatorSearchService.isOfferCreatorSelectionAllowed(item, mode)" (click)="offerCreatorSearchService.selectOfferCreator(item, mode)">
                                            <span>{{ 'offerCreator.selection.action.value' | translate }}</span>
                                        </a>
                                    </div>

                                </ng-container>

                            </ng-container>

                            <!-- Détails du créateur d'offre -->

                            <div>
                                <a [routerLink]="['/account/offer-creator/read', item.id]" [target]="mode === 'quotation' ? '_blank' : '_self'" [queryParams]="{ 'list-redirection-allowed': mode === 'quotation' ? 0 : 1 }">
                                    <span>{{ 'profile.see.action.value' | translate }}</span>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
