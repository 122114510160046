import {Component, OnInit} from '@angular/core';
import {Society} from "@core/shared/models/society";
import {ActivatedRoute, Router} from "@angular/router";
import {CountryService} from "@core/shared/services/country.service";
import {FormControl, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "@core/shared/services/user.service";
import {FormService} from "@core/shared/services/form.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {QuotationService} from "@core/shared/services/quotation.service";
import {Quotation, QuotationOfferCreator, QuotationOfferCreatorStatus, QuotationStatus} from "@core/shared/models/quotation";
import {OfferAttribute} from "@core/shared/models/offer-attribute";
import moment from "moment/moment";
import {Country} from "@core/shared/models/country";
import {User} from "@core/shared/models/user";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {SelectSearchConfiguration} from "@core/components/select/select-search/select-search.component";
import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";
import {Pagination} from "@core/shared/models/pagination";
import {Access} from "@core/shared/models/access";

@Component({
    selector: 'app-core-page-quotation-read',
    templateUrl: './page-quotation-read.component.html',
    styleUrls: ['./page-quotation-read.component.scss'],
    providers: [
        FormService
    ]
})
export class PageQuotationReadComponent implements OnInit {

    protected readonly moment = moment;

    public currentUser: User;

    public quotation: Quotation;

    public countries: Country[] = [];

    public collaboratorSearchConfiguration: SelectSearchConfiguration<User>;

    public collaboratorSearchSourceCallback: (search: string) => Observable<User[]>;

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _snackBar: MatSnackBar,
        private _countryService: CountryService,
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _quotationService: QuotationService,
        public userService: UserService,
        public formService: FormService,
        public translationService: TranslationService
    ) {}

    ngOnInit() {

        this.currentUser = this.userService.currentUser.getValue();

        this._activatedRoute.data.subscribe((data: { society: Society, quotation: Quotation }): void => {

            this.quotation = data.quotation;

            this._initCountries();

            this._initCollaborators();

            this._initForm();

            this._hydrateForm();

            this._initEvents();
        });
    }

    private _initCountries(): void {

        this._countryService.getItemsAPI().subscribe((items: Country[]): void => {

            this.countries = items;
        });
    }

    private _initCollaborators(): void {

        this.collaboratorSearchConfiguration = {
            multiple: false,
            currentSelectionLabel: 'collaborator.selection.current.value',
            searchActionLabel: 'collaborator.search.action.value',
            selectOptionActionLabel: 'collaborator.option.select.action.value',
            itemLabel: (item: User): string => {

                return `${item.firstName} ${item.lastName}${item.service && item.service.length ? `- ${item.service}` : ''}`;
            }
        }

        this.collaboratorSearchSourceCallback = (search: string): Observable<User[]> => {

            const params: string[] = [
                `page=1`,
                'limit=10'
            ];

            if(search && search.length){

                params.push(...[
                    `fullName[lk]=${search}`
                ]);
            }

            if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])){

                return of([]);
            }
            else{

                return this.userService.getPaginationItemSocietyUsers(this.currentUser.id, params).pipe(map((pagination: Pagination<User>): User[] => {

                    return pagination.items;
                }));
            }
        }
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            status: [{ value: 'in-progress' as QuotationStatus, disabled: true }],
            responsibleUser: [{ value: null, disabled: true }, [Validators.required]],
            offerCreatorStatus: [{ value: 'to-process' as QuotationOfferCreatorStatus, disabled: true }],
            offerCreatorResponsibleUser: [{ value: null, disabled: true }],
            offerCreatorNoReplyReason: [{ value: null, disabled: true }, [(control: FormControl) => {

                if(!control.parent){

                    return null;
                }

                if(control.parent.get('offerCreatorStatus').value !== ('no-reply' as QuotationOfferCreatorStatus)){

                    return null;
                }

                if(!!control.value && Boolean(control.value.length)){

                    return null;
                }

                return {
                    'isRequired': {
                        valid: false
                    }
                };
            }]]
        });

        this.formService.submitCallback = (): void => {

            if (this.form.invalid) {

                return;
            }

            const data: Partial<Quotation> = {};

            if(this.userService.hasRole(this.currentUser, 'ROLE_OFFER_CREATOR') && !this.isMine){

                const quotationOfferCreators: QuotationOfferCreator[] = [...this.quotation.offerCreators.filter((item: QuotationOfferCreator): boolean => {

                    return item.offerCreator.id !== this.currentUser.society.id;
                })];

                const quotationOfferCreator: QuotationOfferCreator = this.quotation.offerCreators.find((item: QuotationOfferCreator): boolean => {

                    return item.offerCreator.id === this.currentUser.society.id;
                });

                quotationOfferCreators.push({
                    id: quotationOfferCreator.id,
                    offerCreator: this.currentUser.society,
                    status: this.form.get('offerCreatorStatus').value,
                    noReplyReason: this.form.get('offerCreatorNoReplyReason').value,
                    responsibleUser: this.form.get('offerCreatorResponsibleUser').value
                });

                Object.assign(data, {
                    offerCreators: quotationOfferCreators
                } as Partial<Quotation>);
            }

            if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']) || (this.userService.hasRole(this.currentUser, 'ROLE_OFFER_DISTRIBUTOR') && this.isMine)){

                Object.assign(data, {
                    status: this.form.get('status').value,
                    responsibleUser: this.form.get('responsibleUser').value
                } as Partial<Quotation>);
            }

            this._quotationService.updateItemAPI(this.quotation.id, data).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('quotation.update.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            });
        };
    }

    private _hydrateForm(): void {

        this.form.patchValue({
            status: this.quotation.status,
            responsibleUser: this.quotation.responsibleUser
        });

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']) || (this.userService.hasRole(this.currentUser, 'ROLE_OFFER_DISTRIBUTOR') && this.isMine)){

            if(this.updateAllowed){

                this.form.get('status').enable();

                this.form.get('responsibleUser').enable();
            }
        }

        if(this.userService.hasRole(this.currentUser, 'ROLE_OFFER_CREATOR') && !this.isMine){

            const quotationOfferCreator: QuotationOfferCreator = this.quotation.offerCreators.find((item: QuotationOfferCreator): boolean => {

                return item.offerCreator.id === this.currentUser.society.id;
            });

            this.form.patchValue({
                offerCreatorStatus: quotationOfferCreator.status,
                offerCreatorResponsibleUser: quotationOfferCreator.responsibleUser,
                offerCreatorNoReplyReason: quotationOfferCreator.noReplyReason
            });

            if(this.updateAllowed){

                this.form.get('offerCreatorStatus').enable();

                this.form.get('offerCreatorResponsibleUser').enable();

                this.form.get('offerCreatorNoReplyReason').enable();
            }
        }
    }

    private _initEvents(): void {

        this.form.get('offerCreatorStatus').valueChanges.subscribe((): void => {

            this.form.get('offerCreatorNoReplyReason').patchValue(null);

            this.form.get('offerCreatorNoReplyReason').updateValueAndValidity();

            this.form.get('offerCreatorNoReplyReason').markAsTouched();
        });
    }

    public parsedAttributes(items: OfferAttribute[]): string {

        return items.map((region: OfferAttribute): string => {

            return this.translationService.getFallbackTranslation(region.translations).label;

        }).join(', ');
    }

    public getCountryByCode(code: string): Country {

        return this.countries.find((country: Country): boolean => {

            return country.code === code;

        }) || null;
    }

    public redirectToList(): void {

        this._router.navigate(['account/quotation/list']);
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get isMine(): boolean {

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])){

            return false;
        }

        return this.currentUser.society.id === this.quotation.society.id;
    }

    get displayOfferCreators(): boolean {

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

            return true;
        }

        if(this.userService.hasRole(this.currentUser, 'ROLE_OFFER_CREATOR')){

            return this.isMine;
        }

        return this.userService.hasRole(this.currentUser, 'ROLE_OFFER_DISTRIBUTOR');
    }

    get currentResponsibleUser(): User {

        return this.form.get('responsibleUser').value;
    }

    get currentOfferCreatorResponsibleUser(): User {

        return this.form.get('offerCreatorResponsibleUser').value;
    }

    get updateAllowed(): boolean {

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])){

            return false;
        }

        const conditions: boolean[] = [
            !this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']),
            this.quotation.status === 'in-progress',
            this.currentUser.accesses.some((access: Access): boolean => {

                return access.tag === 'QUOTATION_EDIT_IS_MINE';
            })
        ];

        return conditions.every((value: boolean) => {

            return value === true;
        });
    }
}
